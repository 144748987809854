import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import {
  i18translateType,
  LinkWithTranslationType,
} from "../../@types/types-body";
import Link from "next/link";

export const LinkWhiteHoverGreenNoWhiteSpace: FC<LinkWithTranslationType> = ({
  namespace,
  text,
  link,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <Link
      href={t(`${link}`)}
      id={t(`id-link-${link}`)}
      className="text-white whitespace-nowrap hover:text-crowlyn-light "
    >
      {t(`${text}`)}
    </Link>
  );
};
