import { useTranslation } from "next-i18next";
import { LockLocked } from "@components/icons/lock-locked";
import { ButtonPrimaryRounded } from "@components/buttons/button-primary-rounded";
import { ButtonSecondary } from "@components/buttons/button-secondary-rounded";
import { ButtonHelpRounded } from "@components/buttons/button-help-rounded";
import Link from "next/link";
import { FC, useEffect } from "react";
import { GdprConsent } from "@components/helpers/enums";
import {
  CookieConsent,
  CrowlynRequestId,
} from "@components/helpers/configEnums";
import { FetchPostCall } from "@components/helpers/fetch-call";
import { useRouter } from "next/router";

export const FooterCookiesPanel: FC<{
  gdprAccepted: boolean;
  setGdprAccepted: any;
  setAnalyticsAllowed: any;
}> = ({ gdprAccepted, setGdprAccepted, setAnalyticsAllowed }) => {
  const router = useRouter();
  const { t } = useTranslation("common");
  const url = t(`menu.path.privacy-protection`);

  const confirmCookiesValue = async (variant: string) => {
    try {
      const result = await FetchPostCall("/api/cookie-consent", {
        consent: variant,
      });
      if (result.message === "success") {
        localStorage.setItem(CookieConsent, JSON.stringify(variant));
      }
      if (variant == GdprConsent.All) {
        setAnalyticsAllowed(true);
      }

      setGdprAccepted(true);
    } catch (e) {}
  };

  useEffect(() => {
    function getCookie(name: string) {
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === name + "=") {
            return decodeURIComponent(cookie.substring(name.length + 1));
          }
        }
      }
      return "";
    }
    const cookies = getCookie(CrowlynRequestId);
    if (cookies) {
      localStorage.setItem(CrowlynRequestId, cookies);
      if (localStorage.getItem(CrowlynRequestId)) {
        document.cookie = `cookiename=${CrowlynRequestId} + '=; Max-Age=0`;
      }
    }
  }, []);

  return (
    <div
      className={
        gdprAccepted ? "hidden" : "h-full flex flex-row justify-center"
      }
    >
      <div className="fixed bottom-0 md:top md:fixed md:w-full bg-white text-gray-900 pt-8 z-[9999]">
        <div className="flex flex-col flex-col-revers md:flex-row mx-8 align-middle">
          <div className="md:h-[480px] lg:h-[380px] xl:h-80 2xl:h-64 overflow-auto md:h-auto md:basis-8/12 lg:basis-9/12 flex flex-col">
            <div className="w-4 h-4 md:w-8 md:h-8 lg:w-12 lg:h-12 fill-gray-600 mx-auto">
              {LockLocked}
            </div>
            <h2 className="text-xl pb-2">{t("cookies.title")}</h2>
            <p className="text-justify text-sm">{t("cookies.text_one")}</p>
            <p className="text-justify text-sm">
              {t("cookies.text_two")}{" "}
              <strong className="text-base font-medium">
                „{t("cookies.confirm")}“
              </strong>{" "}
              {t("cookies.text_three")}
            </p>
            <p className="text-justify text-sm">
              {t("cookies.text_four")}{" "}
              <strong className="text-base font-medium">
                „{t("cookies.adjust")}“
              </strong>{" "}
              {t("cookies.text_five")}{" "}
              <strong className="text-base font-medium">
                „{t("cookies.confirm_necessary")}“
              </strong>{" "}
              {t("cookies.text_six")}
              <Link href={url}>{t(`menu.privacy-protection`)}</Link>
            </p>
          </div>
          <div className="md:basis-4/12 lg:basis-3/12">
            <div className="flex flex-col md:mx-12 lg:mx-24">
              <ButtonPrimaryRounded
                id="cookie-button-confirm-all"
                namespace="common"
                text="cookies.confirm"
                confirmButton={() => confirmCookiesValue(GdprConsent.All)}
              />
              <ButtonHelpRounded
                id="cookie-button-confirm-necessary"
                namespace="common"
                text="cookies.confirm_necessary"
                confirmButton={() => confirmCookiesValue(GdprConsent.Technical)}
              />
              <ButtonSecondary
                id="cookie-button-confirm-adjust"
                namespace="common"
                text="cookies.adjust"
                confirmButton={() =>
                  router.push(url, url, { locale: router.locale })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
