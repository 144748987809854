import { CrowlynRequestId } from "@components/helpers/configEnums";

export const FetchPostCall = async (url: string, data: {}) => {
  try {
    const lang = document.documentElement.lang;
    const result = await fetch(url, {
      method: "Post",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
      referrerPolicy: "no-referrer",
      headers: {
        "Accept-Language": lang,
        "Content-Type": "application/json",
        Authorization: `Baerer: ${localStorage.getItem(CrowlynRequestId)}`,
      },
      body: JSON.stringify(data),
    });
    if (result.ok) return await result.json();
    return result;
  } catch (e) {
    return e;
  }
};

export const FetchPostCallBackend = async (
  url: string,
  data: {},
  token: string | undefined,
  secret: string | undefined
) => {
  try {
    const tokenHeader = token === undefined ? "" : token;
    const secretHeader = secret === undefined ? "" : secret;
    const result = await fetch(url, {
      method: "Post",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenHeader}`,
        Service: secretHeader,
      },
      body: JSON.stringify(data),
    });
    if (result.ok) return await result.json();
    return result.status.toString();
  } catch (e) {}
};
