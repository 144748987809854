import { FC } from "react";
import { ButtonRoundedType, i18translateType } from "../../@types/types-body";
import { useTranslation } from "next-i18next";

export const ButtonPrimaryRounded: FC<ButtonRoundedType> = ({
  id,
  namespace,
  text,
  confirmButton,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <button
      key={id}
      id={id}
      className="bg-crowlyn-dark rounded-full text-white px-4 py-2 my-2 whitespace-nowrap text-base md:text-lg hover:text-dark-100 hover:bg-crowlyn-light "
      onClick={() => confirmButton()}
    >
      {t(`${text}`)}
    </button>
  );
};
