import React, { FC } from "react";
import { WidgetFooterText } from "@components/sections/footer/widget-footer-text";
import { WidgetFooterCompany } from "@components/sections/footer/widget-footer-company";
import { WidgetFooterLinks } from "@components/sections/footer/widget-footer-links";
import { WidgetFooterInformationalLinks } from "@components/sections/footer/widget-footer-informational-links";

export const FooterMiddle: FC = () => {
  return (
    <React.Fragment>
      <div className="footer-area bg-crowlyn-night py-section text-white">
        <div className="container mx-auto">
          <div className="grid grid-cols-12 gap-4 justify-items-center py-8">
            <div className="col-span-12 sm:col-span-5 xl:col-span-3">
              <WidgetFooterText />
            </div>

            <div className="col-span-6 xl:col-span-3 md:mt-0">
              <WidgetFooterLinks />
            </div>
            <div className="col-span-6 xl:col-span-3 md:mt-0">
              <WidgetFooterInformationalLinks />
            </div>
            <div className="col-span-12 sm:col-span-5 xl:col-span-3 ">
              <WidgetFooterCompany />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
