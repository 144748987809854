import { FC } from "react";
import { NextSeo, NextSeoProps } from "next-seo";
import siteConfig from "@data/site-config";

interface SeoProps extends NextSeoProps {
  keywords: string | undefined;
  lang: string | undefined;
  path: string | undefined;
  englishPath: string | undefined;
  germanPath: string | undefined;
  image: string | undefined;
}
const baseUrl = "https://www.crowlyn.com";
const CrowlynHead: FC<SeoProps> = ({
  title,
  description,
  keywords,
  lang,
  path,
  englishPath,
  germanPath,
  image,
}) => {
  return (
    <>
      {/* Add hreflang links */}
      <NextSeo
        title={title}
        defaultTitle={siteConfig.name}
        description={description}
        canonical={`${baseUrl}${path}`}
        openGraph={{
          type: "website",
          locale: lang,
          site_name: siteConfig.name,
          images: [
            {
              url: `${baseUrl}${image}`,
              width: 800,
              height: 600,
              alt: "Og Image Alt",
            },
            {
              url: `${baseUrl}${image}`,
              width: 900,
              height: 800,
              alt: "Og Image Alt Second",
            },
          ],
        }}
        twitter={{
          handle: "@handle",
          site: "@CrowlynCompany",
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: "606776600986464",
        }}
        additionalMetaTags={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1 maximum-scale=1",
          },
          {
            name: "application-name",
            content: "Crowlyn",
          },
          {
            name: "apple-mobile-web-app-title",
            content: "PWA Crowlyn",
          },
          {
            name: "mobile-web-app-capable",
            content: "yes",
          },
          {
            name: "format-detection",
            content: "telephone=yes",
          },
          {
            name: "theme-color",
            content: "#007324",
          },
          {
            name: "keywords",
            content: `${keywords}`,
          },
          {
            name: "msapplication-config",
            content: `/icons/browserconfig.xml`,
          },
          {
            name: "msapplication-tap-highlight",
            content: `no`,
          },
          {
            name: "msapplication-TileColor",
            content: "#007324",
          },
          {
            name: "apple-mobile-web-app-title",
            content: `${title}`,
          },
        ]}
        languageAlternates={[
          {
            hrefLang: "en",
            href: `${baseUrl}/en${englishPath}`,
          },
          {
            hrefLang: "de",
            href: `${baseUrl}/de${germanPath}`,
          },
        ]}
        additionalLinkTags={[
          {
            rel: "apple-touch-icon",
            sizes: "",
            href: "/icons/icon-192x192.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "152x152",
            href: "/icons/icon-192x192.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/icons/icon-192x192.png",
          },
          {
            rel: "apple-touch-icon",
            sizes: "167x167",
            href: "/icons/icon-192x192.png",
          },
          {
            rel: "icon",
            sizes: "32x32",
            type: "image/png",
            href: "/icons/icon-192x192.png",
          },
          {
            rel: "icon",
            sizes: "16x16",
            type: "image/png",
            href: "/icons/icon-192x192.png",
          },
          {
            rel: "mask-icon",
            sizes: "16x16",
            color: "",
            href: "/icons/safari-pinned-tab.svg",
          },
          {
            rel: "manifest",
            href: "/manifest.json",
          },
          {
            rel: "shortcut icon",
            href: "/favicon.ico",
          },
        ]}
      ></NextSeo>
    </>
  );
};
export default CrowlynHead;
//<meta property="business:contact_data:street_address" content="">
// <meta property="business:contact_data:locality" content="">
// <meta property="business:contact_data:region" content="">
// <meta property="business:contact_data:country_name" content="United States">