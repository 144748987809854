import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import { LinkWhiteHoverGreenNoWhiteSpace } from "@components/links/link-white-hover-green-no-white-space";
import Link from "next/link";
import { i18translateType } from "../../../@types/types-body";

export const WidgetFooterInformationalLinks: FC = () => {
  const namespace = "common";
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <React.Fragment>
      <div className="mr-0 md:mr-4 align-middle justify-center">
        <span className="text-2xl">{t("menu.usefully_links")}</span>
        <ul className="list-none leading-loose">
          <li>
            {/*
            <Link
              href="https://www.crowlyn-it.com"
              className="text-white hover:text-crowlyn-light "
            >
              {t("footer.external_links.crowlyn_it")}
            </Link>
            */}
          </li>
          <li>
            <Link
              href="https://www.cestouyogy.cz"
              className="text-white hover:text-crowlyn-light "
            >
              {t("footer.external_links.crowlyn_fitness")}
            </Link>
          </li>
          <li>
            <LinkWhiteHoverGreenNoWhiteSpace
              namespace={namespace}
              key={"link-footer"}
              text={"menu.data-protection"}
              link={"menu.path.data-protection"}
            />
          </li>
          <li>
            <LinkWhiteHoverGreenNoWhiteSpace
              namespace={namespace}
              key={"link-footer"}
              text={"menu.cookies"}
              link={"menu.path.cookies"}
            />
          </li>
          <li>
            <LinkWhiteHoverGreenNoWhiteSpace
              namespace={namespace}
              key={"author-rights"}
              text={"menu.author-rights"}
              link={"menu.path.author-rights"}
            />
          </li>
          <li>
            <LinkWhiteHoverGreenNoWhiteSpace
              namespace={namespace}
              key={"privacy-protection"}
              text={"menu.privacy-protection"}
              link={"menu.path.privacy-protection"}
            />
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
