import {FC} from "react";

export const TwitterIconRounded: FC = () => {
    return (
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 1226.37"
                 width="24"
                 height="24"
                 aria-label="Twitter"
                 className="p-1">
              <path
                  d="m714.16,519.28L1160.89,0h-105.86l-387.89,450.89L357.33,0H0l468.49,681.82L0,1226.37h105.87l409.63-476.15,327.18,476.15h357.33l-485.86-707.09h.03Zm-145,168.54l-47.47-67.89L144.01,79.69h162.6l304.8,435.99,47.47,67.89,396.2,566.72h-162.6l-323.31-462.45v-.03Z"/>
            </svg>
    );
};
