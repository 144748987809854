import React, { ChangeEvent, FC, useState } from "react";
import { FooterTopFacts } from "@components/sections/footer/footer-top-facts";
import { useTranslation } from "next-i18next";
import { ButtonPrimaryRounded } from "@components/buttons/button-primary-rounded";
import { FetchPostCall } from "@components/helpers/fetch-call";
import { i18translateType } from "../../../@types/types-body";

export const FooterTop: FC = () => {
  const { t }: i18translateType = useTranslation("common");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const submitNewsletter = async () => {
    if (!emailDisabled) {
      try {
        const result = await FetchPostCall("/api/newsletter", { email: email });
        if (result.status < 300 && result.status >= 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 8000);
          setEmailDisabled(true);
        } else {
          throw error;
        }
      } catch (e: any) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 8000);
      }
      setEmail("");
    }
  };
  return (
    <React.Fragment>
      <div className="bg-crowlyn-dark text-white py-4">
        <div className="container mx-auto">
          <div className="w-full flex flex-row flex-wrap md:flex-nowrap justify-around text-center align-middle">
            <div className="w-full flex flex-col text-center md:text-left mb-5 md:mb-0 align-middle">
              <h2 className="text-2xl italic">
                {t("footer.newsletter_join")}
              </h2>
              <p className="text-sm italic">
                {t("footer.newsletter_why_join")}
              </p>
            </div>
            <div
              className="mb-6 md:mb-0 md:mr-2 md:mt-1 w-full md:w-2/3 mx-auto my-auto"
              key={`form-input-newsletter`}
            >
              <label
                htmlFor="website-admin"
                className="block text-sm font-medium text-gray-900 hidden"
              ></label>
              <div className="flex align-middle">
                <input
                  type="text"
                  id="website-admin"
                  value={email}
                  onChange={handleChange}
                  disabled={emailDisabled}
                  className="
                       bg-gray-50
                       rounded-lg border border-gray-900 placeholder-black text-black
                       focus:ring-crowlyn-mid hover:border-crowlyn-mid
                       block flex-1 min-w-0 w-full text-sm border-gray-300 p-3  "
                  placeholder={t("footer.newsletter_placeholder")}
                />
              </div>
            </div>
            <ButtonPrimaryRounded
              id={"footer-newsletter"}
              namespace={"common"}
              text={"submit_newsletter"}
              confirmButton={() => submitNewsletter()}
            />
          </div>
        </div>
      </div>
      {success ? (
        <div className="absolute w-screen z-30">
          <div
            className="p-4 mb-4 text-sm text-white bg-crowlyn-night rounded-lg"
            role="alert"
          >
            <span className="font-medium">
              {t(`common:newsletter_success`)}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {error ? (
        <div className="absolute w-screen z-30">
          <div
            className="p-4 mb-4 text-sm text-white bg-red-700 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <span className="font-medium">
              {t(`common:newsletter_error_not_send`)}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
