import React, { FC } from "react";
import Image from "next/image";
import Logo from "@images/identity/crowlyn.webp";
import { useTranslation } from "next-i18next";
import { FacebookIconRounded } from "@components/icons/facebook-icon-rounded";
import { InstagramIconRounded } from "@components/icons/instragram-icon-rounded";
import { YoutubeIconRounded } from "@components/icons/youtube-icon-rounded";
import { TwitterIconRounded } from "@components/icons/twitter-icon-rounded";
import { LinkedinIconRounded } from "@components/icons/linkedin-icon-rounded";
import Link from "next/link";
import { i18translateType } from "../../../@types/types-body";
import {DiscordIcon} from "@components/icons/discord-icon";

export const WidgetFooterText: FC = () => {
  const { t }: i18translateType = useTranslation("common");
  return (
    <React.Fragment>
      <div className="mr-0 md:mr-4 align-middle justify-center">
        <div className="text-center">
          <Link
            href="https://www.crowlyn.com"
            target="_blank"
            className="w-full mx-auto text-center"
          >
            <Image
              src={Logo}
              alt="Crowlyn, s.r.o."
              className="mx-auto text-center"
            />
          </Link>
        </div>
        <p className="text-white mt-3 mb-7 text-justify">{t("company_info")}</p>
        <div className="flex justify-center text-center space-x-3">
          <Link
            href="https://www.facebook.com/CrowlynCompany/"
            target="_blank"
            rel="noopener"
            className="fill-white hover:fill-crowlyn-light "
          >
            <FacebookIconRounded />
          </Link>
          <Link
            href="https://www.linkedin.com/company/crowlyn/"
            target="_blank"
            rel="noopener"
            className="fill-white hover:fill-crowlyn-light"
          >
            <LinkedinIconRounded />
          </Link>
          <Link
            href="https://www.instagram.com/crowlyncompany/"
            target="_blank"
            rel="noopener"
            className="fill-white hover:fill-crowlyn-light"
          >
            <InstagramIconRounded />
          </Link>
          <Link
            href="https://twitter.com/CrowlynCompany"
            target="_blank"
            rel="noopener"
            className="fill-black bg-white hover:bg-crowlyn-light rounded"
          >
            <TwitterIconRounded />
          </Link>
          <Link
              href="https://discord.gg/qRUyVwRXQq"
              target="_blank"
              rel="noopener"
              className="fill-black bg-white hover:bg-crowlyn-light rounded"
          >
            <DiscordIcon />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UCGNCzE32mjuYtGUn3mlPJ9g"
            target="_blank"
            rel="noopener"
            className="fill-white hover:fill-crowlyn-light"
          >
            <YoutubeIconRounded />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
