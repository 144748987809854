import React, { FC } from "react";
import { HearthIconSpecial } from "@components/icons/hearth-icon-special";
import Link from "next/link";

export const FooterBottom: FC = () => {
  return (
    <React.Fragment>
      <div className="bg-crowlyn-night py-4">
        <hr  className="border-crowlyn-dark h-3 border-t-8 mb-2"/>
        <div className="container mx-auto">
          <div className="w-full text-base text-center text-white">
            <p className="flex-row flex justify-center">
              <span>© 2020 - {new Date().getFullYear()} Made with</span>
              <span className="fill-crowlyn-light ml-2">
                <HearthIconSpecial />
              </span>
              <span className="ml-2 mr-1">by</span>
              <Link
                href="https://www.crowlyn.com"
                target="_blank"
                className="ml-1 text-crowlyn-light"
              >
                Crowlyn, s.r.o.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
