import React, { FC } from "react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { i18translateType } from "../../../@types/types-body";

export const WidgetFooterLinks: FC = () => {
  const { t }: i18translateType = useTranslation("common");
  return (
    <React.Fragment>
      <div className="mr-0 md:mr-4 align-middle justify-center">
        <span className="text-2xl">{t("menu.usefully_links")}</span>
        <ul className="leading-loose">
          <li>
            <Link href="/" className="text-white hover:text-crowlyn-light ">
              {t("menu.home")}
            </Link>
          </li>
          <li>
            <Link
              href={t("menu.path.services")}
              className="text-white hover:text-crowlyn-light"
            >
              {t("menu.services")}
            </Link>
          </li>
          <li>
            <Link
              href={t("menu.path.company")}
              className="text-white hover:text-crowlyn-light"
            >
              {t("menu.company")}
            </Link>
          </li>
          <li>
            <Link
              href={t("menu.path.blog")}
              className="text-white hover:text-crowlyn-light"
            >
              {t("menu.blog")}
            </Link>
          </li>
          <li>
            <Link
              href={t("menu.path.career")}
              className="text-white hover:text-crowlyn-light"
            >
              {t("menu.career")}
            </Link>
          </li>
          <li>
            <Link
              href={t("menu.path.contact")}
              className="text-white hover:text-crowlyn-light"
            >
              {t("menu.contact")}
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};
