import React, { FC, useEffect, useState } from "react";
import { FooterBottom } from "@components/sections/footer/footer-bottom";
import { FooterMiddle } from "@components/sections/footer/footer-middle";
import { FooterTop } from "@components/sections/footer/footer-top";
import { FooterCookiesPanel } from "@components/sections/footer/footer-cookies-panel";
import dynamic from "next/dynamic";
import { AddGoogleTagManager } from "@components/sections/analytics-components/google-tag";
import { CookieConsent } from "@components/helpers/configEnums";
import { GdprConsent } from "@components/helpers/enums";

const FooterFacebookChat = dynamic(
  () => import("../analytics-components/footer-facebook-chat"),
  {
    suspense: true,
  }
);
export const Footer: FC = () => {
  let [gdprAccepted, setGdprAccepted] = useState(false);
  let [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  useEffect(() => {
    const consentCookies: string | null = localStorage.getItem(CookieConsent);
    if (consentCookies !== null) {
      setGdprAccepted(true);
      if (consentCookies == GdprConsent.All) {
        setAnalyticsAllowed(true);
      }
    }
  }, []);
  return (
    <footer>
      <FooterTop />
      <FooterMiddle />
      <FooterBottom />
      <FooterCookiesPanel
        gdprAccepted={gdprAccepted}
        setGdprAccepted={setGdprAccepted}
        setAnalyticsAllowed={setAnalyticsAllowed}
      />
      <FooterFacebookChat />
      {analyticsAllowed ? <AddGoogleTagManager /> : <></>}
    </footer>
  );
};
