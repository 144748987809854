import {FC} from "react";

export const DiscordIcon: FC = () => {
    return (
        <svg className="p-[1px]"
             width={24}
             height={24}
             id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 58 58">
            <g>
                <path className="st0" d="M47.5,10.8C43.8,9.2,40,8,36,7.3c-0.5,0.9-1.1,2.1-1.5,3c-4.2-0.6-8.5-0.6-12.7,0c-0.4-1-1-2-1.5-3
                                c-4,0.7-7.8,1.9-11.5,3.6C1.7,21.6-0.3,32.1,0.7,42.5c4.2,3.2,9,5.5,14,7.1c1.1-1.5,2.1-3.2,3-4.9c-1.6-0.6-3.2-1.4-4.7-2.3
                                c0.4-0.3,0.8-0.6,1.1-0.9c8.9,4.2,19.2,4.2,28.1,0c0.4,0.3,0.8,0.6,1.2,0.9c-1.5,0.9-3.1,1.7-4.7,2.3c0.9,1.7,1.9,3.3,3,4.9
                                c5.1-1.5,9.8-3.9,14-7.1C56.9,30.5,53.8,20.1,47.5,10.8z M19,36.1c-2.7,0-5-2.5-5-5.6s2.2-5.6,5-5.6s5,2.5,5,5.6S21.8,36.1,19,36.1
                                z M37.4,36.1c-2.7,0-5-2.5-5-5.6s2.2-5.6,5-5.6s5,2.5,5,5.6S40.2,36.1,37.4,36.1z"/>
            </g>
        </svg>

    );
};
