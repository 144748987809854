import React, { FC } from "react";
import { Phone } from "@components/icons/phone";
import { At } from "@components/icons/at";
import { ParagraphWithSvgWithoutTranslation } from "@components/text/ParagraphWithSvgWithoutTranslation";
import { Building } from "@components/icons/building";

export const WidgetFooterCompany: FC = () => {
  return (
    <React.Fragment>
      <div className="col-span-12 sm:col-span-6 lg:col-span-2 mb-[30px]">
        <h3 className="font-normal text-2xl align-bottom mb-4 italic">
          Crowlyn, s.r.o.
        </h3>
        <ParagraphWithSvgWithoutTranslation
          id={1}
          icon={Building}
          text={" Varšavská 715/36, Vinohrady, 120 00 Praha"}
        />
        <ParagraphWithSvgWithoutTranslation
          id={2}
          icon={At}
          text={"info@crowlyn.com"}
        />
        <ParagraphWithSvgWithoutTranslation
          id={3}
          icon={Phone}
          text={" +49 1512 3376347"}
        />
      </div>
    </React.Fragment>
  );
};
