import React, { FC } from "react";
import { ParagraphWithSvgType } from "../../@types/types-body";

export const ParagraphWithSvgWithoutTranslation: FC<ParagraphWithSvgType> = ({
  id,
  text,
  icon,
}) => {
  return (
    <p
      id={`paragraph-with-svg-icon-${id}`}
      key={`paragraph-with-svg-icon-${id}`}
      className="flex my-4 align-bottom"
    >
      <span className="w-4 h-4 fill-white mr-2 ">{icon}</span> {text}
    </p>
  );
};
