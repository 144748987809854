export const GdprConsent = {
  All: "all",
  Technical: "technical",
  Custom: "custom",
};

export const FormProcessing = {
  Empty: "",
  Invalid: "invalid",
  Processing: "running",
  Failed: "failed",
  Success: "success",
  Error: "error_not_send",
};
