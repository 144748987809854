import { FC } from "react";
import { ButtonRoundedType, i18translateType } from "../../@types/types-body";
import { useTranslation } from "next-i18next";

export const ButtonHelpRounded: FC<ButtonRoundedType> = ({
  id,
  namespace,
  text,
  confirmButton,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <button
      key={id}
      id={id}
      className="bg-gray-600 rounded-full text-gray-900 px-4 py-2 my-2 text-base whitespace-nowrap  md:text-lg hover:bg-gray-800"
      onClick={() => confirmButton()}
    >
      {t(`${text}`)}
    </button>
  );
};
